export const environment = {
  production: false, 
  DomainURL: "https://login.vnptvungtau.vn/",  
  DomainDestination: "https://login.vnptvungtau.vn/#/",  
  APIURL: "https://apicrm.vnptvungtau.vn/api/v1/",    
  APIRootURL: "https://apicrm.vnptvungtau.vn/",    
  LoginURL: "https://login.vnptvungtau.vn/",
  CRM: "https://crm.vnptvungtau.vn/#/",
  Website: "https://vnptvungtau.vn/",
  Image: "Image",  
  Company: "Company", 
  Membership: "Membership", 
  Barcode: "Barcode",
  QRcode: "QRcode",
  OrderDelivery: "OrderDelivery", 
  OrderCall: "OrderCall", 
  Download: "Download",  
  LoadingFile: "loading.gif",
  DialogConfigWidth: "80%",
  InitializationString: "",  
  InitializationNumber: 0, 
  CustomerID: 1, 
  ParentID: 1, 
  DieuHanhID: 2, 
  ShopID: 3, 
  ShipperID: 4, 
  ItemCount: 10, 
  CAID: 20,
  TenMienID: 42,
  HoaDonDienTuID: 14,
  Token: "Token",
  TokenAPI: "040621fe-7999-410c-8e06-a3ba249ded43", 
  NhanVienID: "NhanVienID",  
  OTP001: "OTP001",  
  MembershipID: "MembershipID",  
  UploadSuccess: "Upload thành công.",
  UploadNotSuccess: "Upload không thành công.", 
  SaveSuccess: "Lưu thành công.",
  SaveNotSuccess: "Lưu không thành công.",  
  DeleteConfirm: "Bạn muốn xóa dữ liệu này?",      
  DeleteSuccess: "Xóa thành công.",
  DeleteNotSuccess: "Xóa không thành công.",    
  LoginNotSuccess: "Đăng nhập không thành công.",    
  UserNameExists: "UserName đã tồn tại.",
  UserNameRequired: "UserName là bắt buộc.",    
};

